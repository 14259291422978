import { FirebaseAuthProvider } from 'react-admin-firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyCESKo-l5Yjq3v0ktSTeggf9JIlHlZPyPE',
  authDomain: 'personal-services-dev.firebaseapp.com',
  projectId: 'personal-services-dev',
  storageBucket: 'personal-services-dev.appspot.com',
  messagingSenderId: '910155204426',
  appId: '1:910155204426:web:9b4b99e9cdee53aea223b3',
  measurementId: 'G-R3G7JNKS27',
};

export const authProvider = FirebaseAuthProvider(firebaseConfig, {
  logging: true,
  persistence: 'local',
});

export const useAuthProvider = () => {
  return authProvider;
};
