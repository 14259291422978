interface Config {
  debug: boolean;
  apiBaseUrl: string;
  fbaApiBaseUrl: string;
}

const STAGE = process.env.REACT_APP_STAGE;
let config: Config = {
  debug: true,
  apiBaseUrl: `https://fmiem0c2zc.execute-api.us-east-1.amazonaws.com/${STAGE}/chenda`,
  fbaApiBaseUrl: `https://fmiem0c2zc.execute-api.us-east-1.amazonaws.com/${STAGE}/fba`,
};

switch (STAGE) {
  case 'local':
    config = {
      ...config,
      apiBaseUrl: 'http://localhost:4004/chenda',
      fbaApiBaseUrl: 'http://localhost:4000/fba',
    };
    break;
  case 'staging':
    config = {
      ...config,
      debug: false,
    };
    break;
  case 'production':
    config = {
      ...config,
      debug: false,
      apiBaseUrl: 'https://api.carlswann.com/fba',
    };
    break;
  default:
    break;
}

export { config };
