import { FunctionField } from 'react-admin';
import { getOrderCost } from '../../asung-orders.utils';
import { AsungOrder } from '../../types';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'CAD',
  minimumFractionDigits: 2,
});

export const AsungOrderCostField: React.FC = () => {
  return (
    <FunctionField
      label='Order Cost'
      render={(order: AsungOrder) => {
        const orderTotalCost = getOrderCost(order);
        return currencyFormatter.format(orderTotalCost.toNumber());
      }}
    />
  );
};
