/* eslint-disable  @typescript-eslint/ban-ts-comment */
import { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { RaRecord } from 'ra-core';

import { TitlePropType } from 'react-admin';
import { InfinitePagination } from 'react-admin';
import { ListView, ListViewProps } from 'react-admin';
import { CursorListBase } from './CursorListBase';
import { CursorListControllerProps } from './hooks/useCursorListController';

export const CursorList = <RecordType extends RaRecord = any>({
  debounce,
  disableAuthentication,
  disableSyncWithLocation,
  exporter,
  filter = defaultFilter,
  filterDefaultValues,
  pagination = defaultPagination,
  perPage = 10,
  queryOptions,
  resource,
  sort,
  storeKey,
  ...rest
}: CursorListProps<RecordType>): ReactElement => (
  <CursorListBase<RecordType>
    debounce={debounce}
    disableAuthentication={disableAuthentication}
    disableSyncWithLocation={disableSyncWithLocation}
    exporter={exporter}
    filter={filter}
    filterDefaultValues={filterDefaultValues}
    perPage={perPage}
    queryOptions={queryOptions}
    resource={resource}
    sort={sort}
    storeKey={storeKey}
  >
    <ListView<RecordType> {...rest} pagination={pagination} />
  </CursorListBase>
);

const defaultPagination = <InfinitePagination />;
const defaultFilter = {};

export interface CursorListProps<RecordType extends RaRecord = any> extends CursorListControllerProps<RecordType>, ListViewProps {}

CursorList.propTypes = {
  // the props you can change
  // @ts-ignore-line
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  aside: PropTypes.element,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  emptyWhileLoading: PropTypes.bool,
  filter: PropTypes.object,
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  // @ts-ignore-line
  pagination: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  perPage: PropTypes.number,
  //@ts-ignore-line
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(['ASC', 'DESC'] as const),
  }),
  sx: PropTypes.any,
  title: TitlePropType,
  // the props managed by react-admin
  disableSyncWithLocation: PropTypes.bool,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  resource: PropTypes.string,
};
