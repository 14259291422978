export enum AsungOrderStatus {
  DRAFT = 'DRAFT',
  PLACING = 'PLACING',
  PLACED = 'PLACED',
  SHIPPED = 'SHIPPED',
}

export enum AsungOrderPrepTier {
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
}

export enum AsungOrderDiscrepancyType {
  PRICE_INCREASE = 'PRICE_INCREASE',
  QUANTITY_INCREASE = 'QUANTITY_INCREASE',
  ORPHANED_UNITS = 'ORPHANED_UNITS',
}

export type AsungOrder = {
  id: string;
  status: AsungOrderStatus;
  asungRef?: string;
  shipmentAmazonRef?: string;

  discrepancies?: {
    type: AsungOrderDiscrepancyType;
    description: string;
  }[];

  items: {
    sellerSku: string;
    quantity: number;

    asungOptions: {
      productRef: string;
      optionCode: string;
      quantity: number;

      uomSizes: {
        size: number;
        price: number;
        quantity: number;
        optionCode: string;
        weightInLbs: number;
      }[];
    }[];

    projectedStats: {
      amazonReturnAfterFees: number;
      monthlySales: number;
      monthlyCSales: number;
    };

    historicalStats?: {
      numberOfSales: number;
      inStockDurationMills: number;
      adjustedUnitPrice: number;
      unitRoiAmount: number;
      amazonReturnAfterFees: number;
    };
  }[];

  boxes?: {
    boxNumber: number;
    weightInKgs: number;
    products: {
      fnSku: string;
      quantity: number;
    }[];
    dimensionsInCm: {
      length: number;
      width: number;
      height: number;
    };
  }[];
};
