import React from 'react';
import { FbaInventoryItemsDataGrid } from '../components';
import { CursorList } from '../../../components/CursorList/CursorList';
import { BooleanInput, TextInput } from 'react-admin';

const inventoryItemsFilters = [
  <TextInput label='Seller Sku' source='sellerSku' alwaysOn />,
  <BooleanInput label='Chenda Verified' source='chendaVerified' alwaysOn />,
];

export const FbaInventoryItemsList: React.FC = (props) => {
  return (
    <CursorList {...props} perPage={25} filters={inventoryItemsFilters}>
      <FbaInventoryItemsDataGrid />
    </CursorList>
  );
};
