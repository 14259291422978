import { Grid, Typography } from '@mui/material';
import { VerifiedProducts } from './components';

export const DashboardPage: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>Chenda Dashboard</Typography>
      </Grid>

      <Grid item xs={6}>
        <VerifiedProducts />
      </Grid>
    </Grid>
  );
};
