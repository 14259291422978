import axios from 'axios';
import { DataProvider, RaRecord, combineDataProviders } from 'ra-core';
import { PaginatedApiResponse } from '../types';
import { config } from '../config';
import { authProvider } from './use-auth-provider';
import { getAuth as getFirebaseAuth } from 'firebase/auth';

const createDataProvider = (baseUrl: string) => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const currentUser = getFirebaseAuth().currentUser;

      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    async (error) => error
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response } = error;

      const shouldLogout = response?.data?.shouldLogout || response?.status === 401;
      if (shouldLogout) {
        await authProvider.logout({});
        window.location.href = '/login';
      }

      throw error;
    }
  );

  const getList: DataProvider['getList'] = async <RecordType extends RaRecord = any>(resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const isNextPageCursor = /[a-z]/i.test(page.toString());

    const { data } = await axiosInstance.get<PaginatedApiResponse<RecordType>>(`/${resource}`, {
      params: {
        ...(isNextPageCursor && { nextPageCursor: page }),
        ...(!isNextPageCursor && page && { page }),
        ...(perPage && { limit: perPage }),
        ...(field && { sortField: field }),
        ...(order && { sortDirection: order?.toLowerCase() }),
        filters: params.filter,
      },
    });

    // already in the correct format
    if ((data as any).pageInfo) {
      return data as any;
    }

    return {
      data: data.entities,
      total: data.meta.totalItems,
    };
  };

  const getOne: DataProvider['getOne'] = <RecordType extends RaRecord = any>(resource, params) => {
    const urlParts = [resource, params.id].filter((urlPart) => !!urlPart);
    return axiosInstance.get<RecordType>(`/${urlParts.join('/')}`);
  };

  const getMany: DataProvider['getMany'] = async <RecordType extends RaRecord = any>(resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const isNextPageCursor = /[a-z]/i.test(page.toString());

    const { data } = await axiosInstance.get<PaginatedApiResponse<RecordType>>(`/${resource}`, {
      params: {
        ...(isNextPageCursor && { nextPageCursor: page }),
        ...(!isNextPageCursor && page && { page }),
        ...(perPage && { limit: perPage }),
        ...(field && { sortField: field }),
        ...(order && { sortDirection: order?.toLowerCase() }),
        ids: params.ids,
      },
    });

    // already in the correct format
    if ((data as any).pageInfo) {
      return data as any;
    }

    return {
      data: data.entities,
      total: data.meta.totalItems,
    };
  };

  const getManyReference: DataProvider['getManyReference'] = async <RecordType extends RaRecord = any>(resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const isNextPageCursor = /[a-z]/i.test(page.toString());

    const { data } = await axiosInstance.get<PaginatedApiResponse<RecordType>>(`/${resource}`, {
      params: {
        ...(isNextPageCursor && { nextPageCursor: page }),
        ...(!isNextPageCursor && page && { page }),
        ...(perPage && { limit: perPage }),
        ...(field && { sortField: field }),
        ...(order && { sortDirection: order?.toLowerCase() }),
        filters: params.filter,
        [params.target]: params.id,
      },
    });

    // already in the correct format
    if ((data as any).pageInfo) {
      return data as any;
    }

    return {
      data: data.entities,
      total: data.meta.totalItems,
    };
  };

  const update: DataProvider['update'] = <RecordType extends RaRecord = any>(resource, params) => {
    return axiosInstance.patch<RecordType>(`/${resource}/${params.id}`, {
      ...params.data,
    });
  };

  const updateMany: DataProvider['updateMany'] = async <RecordType extends RaRecord = any>(resource, params) => {
    const entityIds: string[] = params.ids;

    const updatePromises = entityIds.map((entityId) =>
      axiosInstance.patch<RecordType>(`/${resource}/${entityId}`, {
        ...params.data,
      })
    );

    const updatedEntities = await Promise.all(updatePromises);
    return {
      data: updatedEntities.map(({ data }) => data.id),
    };
  };

  const create: DataProvider['create'] = async <RecordType extends RaRecord = any>(resource, params) => {
    const data = params?.data;
    if (data?.attachment?.rawFile) {
      const base64EncodedFile = await toBase64(data.attachment.rawFile);

      delete data.attachment.rawFile;
      delete data.attachment.src;

      data.attachment.base64 = base64EncodedFile;
    }

    return axiosInstance.post<RecordType>(`/${resource}`, {
      ...params.data,
    });
  };

  const deleteOne: DataProvider['delete'] = <RecordType extends RaRecord = any>(resource, params) => {
    return axiosInstance.delete<RecordType>(`/${resource}/${params.id}`);
  };

  const deleteMany: DataProvider['deleteMany'] = async <RecordType extends RaRecord = any>(resource, params) => {
    const entityIds: string[] = params.ids;

    const deletePromises = entityIds.map((entityId) => axiosInstance.delete<RecordType>(`/${resource}/${entityId}`));

    const deletedEntities = await Promise.all(deletePromises);
    return {
      data: deletedEntities.map(({ data }) => data.id),
    };
  };

  const dataProvider: DataProvider = {
    getList,
    getOne,
    getMany,
    getManyReference,
    update,
    updateMany,
    create,
    delete: deleteOne,
    deleteMany,
  };

  return dataProvider;
};

const apiDataProvider = createDataProvider(config.apiBaseUrl);
const fbaApiDataProvider = createDataProvider(config.fbaApiBaseUrl);

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case 'catalogs':
    case 'inventory-items':
    case 'orders':
    case 'order-items':
    case 'shipments':
    case 'shipment-items':
      return fbaApiDataProvider;
    default:
      return apiDataProvider;
  }
});

export const useDataProvider = () => {
  return dataProvider;
};
