import React from 'react';
import { Typography, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { AppBar as BaseAppBar, AppBarProps, useUserMenu, UserMenu, Logout, useGetList } from 'react-admin';
import { useNavigate } from 'react-router-dom';

// It's important to pass the ref to allow Material UI to manage the keyboard navigation
const SettingsMenuItem = React.forwardRef((props, ref) => {
  // We are not using MenuItemLink so we retrieve the onClose function from the UserContext
  const { onClose } = useUserMenu();
  const navigate = useNavigate();

  const organizationList = useGetList('organizations');
  const organizationId = organizationList.data?.[0].id;

  const handleClick = () => {
    onClose();
    navigate(`/organizations/${organizationId}`); // navigate to edit page for org
  };

  if (!organizationId) return null;

  return (
    <MenuItem
      onClick={handleClick}
      ref={ref as any}
      // It's important to pass the props to allow Material UI to manage the keyboard navigation
      {...props}
    >
      <ListItemIcon>
        <SettingsIcon fontSize='small' />
      </ListItemIcon>
      <ListItemText>Organization Settings</ListItemText>
    </MenuItem>
  );
});

export const AppBar: React.FC<AppBarProps> = (props) => (
  <BaseAppBar
    {...props}
    userMenu={
      <UserMenu>
        <SettingsMenuItem />
        <Logout />
      </UserMenu>
    }
  >
    <Typography flex='1' variant='h6' id='react-admin-title'></Typography>
  </BaseAppBar>
);
