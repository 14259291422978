import { defaultTheme } from 'react-admin';
import { ThemeOptions } from '@mui/material';
import { typographyOptions } from './typography';
import { componentsOptions } from './components/components.options';

export const themeOptions: ThemeOptions = {
  ...defaultTheme,
  typography: typographyOptions,
  components: componentsOptions,
};
