import { ReactNode } from 'react';

import { ResourceContextProvider } from 'ra-core';
import { RaRecord } from 'react-admin';
import { ListContextProvider } from 'react-admin';
import { InfinitePaginationContext } from 'react-admin';
import { CursorListControllerProps, useCursorListController } from './hooks/useCursorListController';

export const CursorListBase = <RecordType extends RaRecord = any>({ children, ...props }: CursorListControllerProps<RecordType> & { children: ReactNode }) => {
  const controllerProps = useCursorListController<RecordType>(props);
  return (
    <ResourceContextProvider value={props.resource}>
      <ListContextProvider value={controllerProps as any}>
        <InfinitePaginationContext.Provider
          value={{
            hasNextPage: controllerProps.hasNextPage!,
            fetchNextPage: controllerProps.fetchNextPage as any,
            isFetchingNextPage: controllerProps.isFetchingNextPage,
            hasPreviousPage: controllerProps.hasPreviousPage!,
            fetchPreviousPage: controllerProps.fetchPreviousPage as any,
            isFetchingPreviousPage: controllerProps.isFetchingPreviousPage,
          }}
        >
          {children}
        </InfinitePaginationContext.Provider>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};
