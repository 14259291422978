import React from 'react';
import { Datagrid, FunctionField, NumberField, TextField } from 'react-admin';
import { getNumberOfAsungUnits, getNumberOfUnits } from '../asung-orders.utils';
import { AsungOrderPrepPricingTierField } from './fields/prep-pricing-tier.field';
import { AsungOrderCostField } from './fields/order-cost.field';

export const AsungOrdersDataGrid: React.FC = () => {
  return (
    <Datagrid rowClick='show'>
      <TextField<any> source='status' label='Status' />
      <NumberField<any> source='items.length' label='# of Skus' />

      <FunctionField label='# of Units' render={(order) => getNumberOfUnits(order).toString()} />
      <FunctionField label='# of Asung Units' render={getNumberOfAsungUnits} />

      <FunctionField label='Prep Pricing Level' render={() => <AsungOrderPrepPricingTierField />} />
      <FunctionField label='Order Cost' render={() => <AsungOrderCostField />} />
    </Datagrid>
  );
};
