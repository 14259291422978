import { useInfiniteQuery, UseInfiniteQueryOptions, UseInfiniteQueryResult, useQueryClient } from 'react-query';

import { RaRecord, GetListParams, GetInfiniteListResult, useDataProvider } from 'react-admin';

export const useCursorGetList = <RecordType extends RaRecord = any>(
  resource: string,
  params: Partial<GetListParams> = {},
  options?: UseInfiniteQueryOptions<GetInfiniteListResult<RecordType>, Error>
): UseInfiniteGetListHookValue<RecordType> => {
  const { pagination = { page: 1, perPage: 25 }, sort = { field: 'id', order: 'DESC' }, filter = {}, meta } = params;
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();

  const result = useInfiniteQuery<GetInfiniteListResult<RecordType>, Error, GetInfiniteListResult<RecordType>>(
    [resource, 'getInfiniteList', { pagination, sort, filter, meta }],
    ({ pageParam = pagination.page }) =>
      dataProvider
        .getList<RecordType>(resource, {
          pagination: {
            page: pageParam,
            perPage: pagination.perPage,
          },
          sort,
          filter,
          meta,
        })
        .then(({ data, pageInfo, total }) => ({
          data,
          total,
          pageParam,
          pageInfo,
        })),
    {
      onSuccess: (data) => {
        // optimistically populate the getOne cache
        data.pages.forEach((page) => {
          page.data.forEach((record) => {
            queryClient.setQueryData([resource, 'getOne', { id: String(record.id), meta }], (oldRecord) => oldRecord ?? record);
          });
        });
      },
      ...options,
      getNextPageParam: (lastLoadedPage) => {
        if (!lastLoadedPage.pageInfo) {
          throw new Error('Last loaded page missing page info');
        }

        if (lastLoadedPage.pageInfo.hasNextPage) {
          return (lastLoadedPage.pageInfo as any).nextPageCursor;
        }
      },
      getPreviousPageParam: (lastLoadedPage) => {
        if (!lastLoadedPage.pageInfo) {
          throw new Error('Last loaded page missing page info');
        }

        if (lastLoadedPage.pageInfo.hasNextPage) {
          return (lastLoadedPage.pageInfo as any).previousPageCursor;
        }
      },
    }
  );

  return (
    result.data
      ? {
          ...result,
          data: result.data,
          total: result.data?.pages[0]?.total ?? undefined,
        }
      : result
  ) as UseInfiniteQueryResult<GetInfiniteListResult<RecordType>, Error> & {
    total?: number;
  };
};

export type UseInfiniteGetListHookValue<RecordType extends RaRecord = any> = UseInfiniteQueryResult<GetInfiniteListResult<RecordType>, Error> & {
  total?: number;
  pageParam?: number;
};
