import { Decimal } from 'decimal.js';
import { AsungOrder, AsungOrderPrepTier } from './types/asung-orders.types';

export const getUomSizeCost = (uomSize: AsungOrder['items'][number]['asungOptions'][number]['uomSizes'][number]) => {
  return new Decimal(uomSize.price).times(uomSize.quantity);
};

export const getUomSizesCost = (uomSizes: AsungOrder['items'][number]['asungOptions'][number]['uomSizes']) => {
  return uomSizes.reduce((acc, uomSize) => {
    return acc.add(getUomSizeCost(uomSize));
  }, new Decimal(0));
};

export const getUomSizesWeightInLbs = (uomSizes: AsungOrder['items'][number]['asungOptions'][number]['uomSizes']) => {
  return uomSizes.reduce((acc, uomSize) => {
    return acc.add(uomSize.weightInLbs);
  }, new Decimal(0));
};

export const getAsungOptionCost = (option: AsungOrder['items'][number]['asungOptions'][number]) => {
  return getUomSizesCost(option.uomSizes);
};

export const getAsungOptionsCost = (asungOptions: AsungOrder['items'][number]['asungOptions']) => {
  return asungOptions.reduce((acc, asungOption) => {
    return acc.add(getAsungOptionCost(asungOption));
  }, new Decimal(0));
};

export const getOrderItemCost = (item: AsungOrder['items'][number]) => {
  return getAsungOptionsCost(item.asungOptions);
};

export const getOrderItemPackCount = (item: AsungOrder['items'][number]) => {
  return item.asungOptions.reduce((acc, option) => {
    return acc.add(option.quantity);
  }, new Decimal(0));
};

export const getOrderItemWeight = (item: AsungOrder['items'][number]) => {
  return item.asungOptions.reduce((acc, option) => {
    return acc.add(getUomSizesWeightInLbs(option.uomSizes));
  }, new Decimal(0));
};

export const getOrderItemShippingCost = (item: AsungOrder['items'][number], costPerLb = 0.3) => {
  const weightInLbs = getOrderItemWeight(item);
  return weightInLbs.times(costPerLb);
};

export const getOrderItemTotalCostWithTaxes = (order: AsungOrder, item: AsungOrder['items'][number]) => {
  const prepTier = getOrderPrepTier(order);
  const packCount = getOrderItemPackCount(item);

  const itemCost = getOrderItemCost(item);
  const prepCost = getPrepCost({ prepTier, packCount: packCount.toNumber() });
  const shippingCost = getOrderItemShippingCost(item);

  const taxFactor = new Decimal(1).add(0.05);
  return itemCost.add(prepCost).add(shippingCost).times(taxFactor);
};

export const getOrderItemHistoricalRoiAmount = (order: AsungOrder, item: AsungOrder['items'][number]) => {
  if (!item.historicalStats) return null;

  const itemTotalWithTaxes = getOrderItemTotalCostWithTaxes(order, item);
  const amazonReturnAfterFees = new Decimal(item.historicalStats.amazonReturnAfterFees).times(item.quantity);

  return new Decimal(amazonReturnAfterFees).sub(itemTotalWithTaxes);
};

export const getOrderItemProjectedRoiAmount = (order: AsungOrder, item: AsungOrder['items'][number]) => {
  const itemTotalWithTaxes = getOrderItemTotalCostWithTaxes(order, item);
  const amazonReturnAfterFees = new Decimal(item.projectedStats.amazonReturnAfterFees).times(item.quantity);

  return new Decimal(amazonReturnAfterFees).sub(itemTotalWithTaxes);
};

export const getOrderItemsCost = (items: AsungOrder['items']) => {
  return items.reduce((acc, item) => {
    return acc.add(getOrderItemCost(item));
  }, new Decimal(0));
};

export const getOrderCost = (order: AsungOrder) => {
  return getOrderItemsCost(order.items);
};

export const getNumberOfAsungUnits = (order: AsungOrder) => {
  return order.items.reduce((itemAccumulator, item) => {
    return item.asungOptions.reduce((optionAccumulator, option) => {
      return option.uomSizes.reduce((acc, uomSize) => {
        return acc + uomSize.size * uomSize.quantity;
      }, optionAccumulator);
    }, itemAccumulator);
  }, 0);
};

export const getNumberOfUnits = (order: AsungOrder) => {
  return order.items.reduce((acc, item) => {
    return acc + item.quantity;
  }, 0);
};

export const getOrderPrepTier = (order: AsungOrder) => {
  const numberOfUnits = getNumberOfUnits(order);

  if (numberOfUnits >= 700) return AsungOrderPrepTier.LEVEL_3;
  if (numberOfUnits >= 200) return AsungOrderPrepTier.LEVEL_2;

  return AsungOrderPrepTier.LEVEL_1;
};

// Copy/Pasted from chenda microservice
export const getPrepCost = ({ prepTier, packCount = 1 }: { prepTier: AsungOrderPrepTier; packCount?: number }) => {
  let basePrepFee: Decimal;
  switch (prepTier) {
    case AsungOrderPrepTier.LEVEL_1:
      basePrepFee = new Decimal(0.79);
      break;
    case AsungOrderPrepTier.LEVEL_2:
      basePrepFee = new Decimal(0.69);
      break;
    case AsungOrderPrepTier.LEVEL_3:
      basePrepFee = new Decimal(0.59);
      break;
    default:
      throw new Error(`[getPrepCost] unknown prepTier[${prepTier}]`);
  }

  const labelAndPolyBagFee = new Decimal(0.3);
  const bubleWrapFee = new Decimal(0.06); // Taken from prep invoice on order 33635

  const multiPackFee = new Decimal(packCount).minus(1).times(0.2);
  const prepCost = basePrepFee.add(labelAndPolyBagFee).add(bubleWrapFee).add(multiPackFee);

  return prepCost.toNumber();
};
