// LoginPage.js
import { Login } from 'react-admin';
import * as firebaseui from 'react-firebaseui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';

// Configure FirebaseUI.
const uiConfig: firebaseui.Props['uiConfig'] = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '#/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        // Forces account selection even when one account
        // is available.
        prompt: 'select_account',
      },
    },
  ],
};

const SignInScreen = () => <StyledFirebaseAuth firebaseAuth={firebase.auth()} uiConfig={uiConfig} />;

export const LoginPage = (props) => (
  <Login {...props}>
    <SignInScreen {...props} />
  </Login>
);
