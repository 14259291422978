import { Create, SimpleForm, required, NumberInput, BooleanInput } from 'react-admin';

export const CreateAsungOrder: React.FC = () => {
  return (
    <Create redirect='show'>
      <SimpleForm>
        <NumberInput source='budget' label='Budget' validate={required()} />
        <NumberInput source='minHistoricalRoiPercentage' defaultValue={0.03} label='Min Historical ROI Percentage' validate={required()} />
        <NumberInput source='minProjectedRoiPercentage' defaultValue={0.04} label='Min Projected ROI Percentage' validate={required()} />
        <NumberInput source='maxInStockWeeks' defaultValue={4} label='Max In Stock Weeks' validate={required()} />

        <NumberInput source='maxUnits' defaultValue={200} label='Max Units' />
        <NumberInput source='maxInStockUnits' defaultValue={20} label='Max Units Per Item' />

        <NumberInput source='minMonthlySales' defaultValue={5} label='Min Monthly Sales' />
        <NumberInput source='minMonthlyCSales' defaultValue={2} label='Min Monthly C-Sales' />

        <NumberInput source='maxRefundPercentage' defaultValue={0} label='Max Refund Percentage' />

        <BooleanInput source='skipUnsoldProducts' defaultValue={true} label='Skip Unsold Products' validate={required()} />
        <BooleanInput source='skipSoldProducts' defaultValue={false} label='Skip Sold Products' validate={required()} />
      </SimpleForm>
    </Create>
  );
};
