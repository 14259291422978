import React from 'react';
import { AsungOrdersDataGrid } from '../components';
import { CursorList } from '../../../components/CursorList/CursorList';

export const AsungOrdersList: React.FC = (props) => {
  return (
    <CursorList {...props} perPage={25}>
      <AsungOrdersDataGrid />
    </CursorList>
  );
};
