import { Resource } from 'react-admin';
import InventoryIcon from '@mui/icons-material/Inventory';
import { ShowFbaInventoryItem, FbaInventoryItemsList, EditFbaInventoryItem } from './pages';

export const FbaInventoryItemsResource = (
  <Resource
    name='fba-inventory-items'
    icon={InventoryIcon}
    list={FbaInventoryItemsList}
    show={ShowFbaInventoryItem}
    edit={EditFbaInventoryItem}
    options={{ label: 'FBA Inventory' }}
  />
);
