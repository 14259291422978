import React from 'react';
import { Admin } from 'react-admin';
import { useAuthProvider, useDataProvider } from './hooks';
import { LoginPage } from './pages';
import { AppLayout } from './layouts';
import { themeOptions } from './theme';
import { resources } from './resources';
import { DashboardPage } from './pages/DashboardPage';

const darkTheme = {
  palette: {
    mode: 'dark',
  },
} as const;

export const App: React.FC = () => {
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();

  return (
    <Admin
      requireAuth
      disableTelemetry
      title='Chenda Dashboard'
      defaultTheme='dark'
      dashboard={DashboardPage}
      darkTheme={darkTheme}
      loginPage={LoginPage}
      layout={AppLayout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={themeOptions}
      children={resources}
    />
  );
};
