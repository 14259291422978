export type FbaInventoryItem = {
  id: string;
  asin: string;
  itemName: string;
  sellerSku: string;
  externalId: string;

  chendaVerified: boolean;

  asungOptions: {
    productRef: string;
    optionCode: string;
    quantity: number;
    stock: number;

    uomSizes: {
      size: number;
      price: number;
      stock: number;
      optionCode: string;
    }[];
  }[];
};

export enum FbaInventoryItemStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
