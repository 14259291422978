import React from 'react';
import { Edit, TextInput, SimpleForm, SelectInput, required, BooleanInput, SimpleFormIterator, ArrayInput, NumberInput } from 'react-admin';
import { FbaInventoryItemStatus } from '../types';

export const EditFbaInventoryItem: React.FC = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SelectInput
          source='status'
          choices={Object.values(FbaInventoryItemStatus).map((status) => ({
            id: status,
            name: status,
          }))}
          validate={required()}
        />

        <BooleanInput source='chendaVerified' label='Chenda Verified' />
        <TextInput source='notes' label='Notes' multiline />

        <ArrayInput source='asungOptions'>
          <SimpleFormIterator inline>
            <TextInput source='optionCode' label='Option Code' />
            <NumberInput source='quantity' label='Quantity' />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
