import React, { useEffect, useMemo, useState } from 'react';
import { Decimal } from 'decimal.js';
import Papa from 'papaparse';
import { sentence as sentenceCase } from 'case';
import downloadJs from 'downloadjs';
import {
  Show,
  TextField,
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  NumberField,
  useShowContext,
  FunctionField,
  TopToolbar,
  useCreate,
  Button,
  EditButton,
  FileInput,
  SimpleForm,
  FileField,
  required,
  Create,
  useNotify,
} from 'react-admin';
import dayjs from 'dayjs';
import { Link, Modal, Box, Grid, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { AsungOrder, AsungOrderStatus } from '../types';
import {
  getOrderCost,
  getOrderItemCost,
  getOrderItemHistoricalRoiAmount,
  getOrderItemProjectedRoiAmount,
  getOrderItemTotalCostWithTaxes,
} from '../asung-orders.utils';
import { AsungOrderCostField } from '../components/fields/order-cost.field';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'CAD',
  minimumFractionDigits: 2,
});

const UploadBoxDetailsButton = () => {
  const notify = useNotify();
  const { record, refetch } = useShowContext<AsungOrder>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (record?.status !== AsungOrderStatus.PLACED) {
    return null;
  }

  const onSuccess = () => {
    notify('Box details uploaded successfully', { type: 'success' });
    refetch();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} startIcon={<UploadFileIcon />} label='Upload Box Details' />
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box height={400} width={600}>
          <Create redirect={false} resource={`asung-orders/${record?.id}/boxes`} mutationOptions={{ onSuccess }}>
            <SimpleForm>
              <FileInput
                source='attachment'
                label='Box Details File'
                validate={[required()]}
                maxSize={5_000_000}
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              >
                <FileField<any> source='src' title='title' />
              </FileInput>
            </SimpleForm>
          </Create>
        </Box>
      </Modal>
    </>
  );
};

const DownloadPackListButton = () => {
  const { record } = useShowContext<AsungOrder>();
  const [getPackList, { isLoading, data }] = useCreate(`asung-orders/${record?.id}/pack-list`);

  useEffect(() => {
    if (!data) return;

    const csvString = Papa.unparse(data.packList);
    downloadJs(csvString, `${record?.id}-packlist.csv`);
  }, [data]);

  if (record?.status !== AsungOrderStatus.PLACED) {
    return null;
  }

  return <Button onClick={() => getPackList()} disabled={isLoading} startIcon={<DownloadIcon />} label='Download PackList' />;
};

const DownloadItemLabelsButton = () => {
  const { record } = useShowContext<AsungOrder>();
  const [getItemLabels, { isLoading, data }] = useCreate(`asung-orders/${record?.id}/item-labels`);

  useEffect(() => {
    if (!data) return;

    const link = document.createElement('a');

    link.href = data.downloadUrl;
    link.setAttribute('download', `${record?.id}-item-labels.pdf`);
    document.body.appendChild(link);

    link.click();
  }, [data]);

  if (record?.status !== AsungOrderStatus.PLACED) {
    return null;
  }

  return <Button onClick={() => getItemLabels()} disabled={isLoading} startIcon={<DownloadIcon />} label='Download Item Labels' />;
};

const DownloadUploadCsvButton = () => {
  const { record } = useShowContext<AsungOrder>();
  const [getUploadCsv, { isLoading, data }] = useCreate(`asung-orders/${record?.id}/upload-csv`);

  useEffect(() => {
    if (!data) return;

    const csvString = Papa.unparse(data.data);
    const timestampString = dayjs().format('YYYY-MM-DD-HH-mm-ss');

    downloadJs(csvString, `order-${record?.id}-${timestampString}.csv`);
  }, [data]);

  if (record?.status !== AsungOrderStatus.DRAFT) {
    return null;
  }

  return <Button onClick={() => getUploadCsv()} disabled={isLoading} startIcon={<DownloadIcon />} label='Download CSV' />;
};

const EditOrderButton = () => {
  const { record } = useShowContext<AsungOrder>();
  if (record?.status !== AsungOrderStatus.DRAFT) {
    return null;
  }

  return <EditButton />;
};

const ShowAsungOrderActions = () => (
  <TopToolbar>
    <DownloadUploadCsvButton />
    <UploadBoxDetailsButton />
    <DownloadPackListButton />
    <DownloadItemLabelsButton />
    <EditOrderButton />
  </TopToolbar>
);

const ShowAsungOrderLayout: React.FC = () => {
  const { record } = useShowContext<AsungOrder>();

  const orderTotalCost = useMemo(() => {
    if (!record) return new Decimal(0);

    return getOrderCost(record);
  }, [record]);

  const orderProjectedRoiAmount = useMemo(() => {
    if (!record) return new Decimal(0);

    return record.items.reduce((acc, item) => {
      return acc.add(getOrderItemProjectedRoiAmount(record, item));
    }, new Decimal(0));
  }, [record]);

  const orderProjectedRoiPercent = useMemo(() => {
    if (!record) return new Decimal(0);

    const orderTotalWithTaxesAndShipping = record.items.reduce((acc, item) => {
      return acc.add(getOrderItemTotalCostWithTaxes(record, item));
    }, new Decimal(0));

    if (orderTotalWithTaxesAndShipping.equals(0)) return new Decimal(0);
    return orderProjectedRoiAmount.div(orderTotalWithTaxesAndShipping).toDecimalPlaces(2);
  }, [orderTotalCost, orderProjectedRoiAmount]);

  return (
    <SimpleShowLayout>
      <TextField<any> source='status' label='Status' />
      <TextField<any> source='asungRef' label='Asung Reference #' />

      <FunctionField<AsungOrder>
        label='Fba Shipment #'
        render={(order) => {
          if (!order.shipmentAmazonRef) return null;

          return (
            <Link href={`https://sellercentral.amazon.ca/fba/inbound-shipment/summary/${order.shipmentAmazonRef}`} target='_blank'>
              {order.shipmentAmazonRef}
            </Link>
          );
        }}
      />

      <FunctionField label='Order Cost' render={() => <AsungOrderCostField />} />
      <FunctionField label='Projected Roi Amount' render={() => currencyFormatter.format(orderProjectedRoiAmount.toNumber())} />
      <FunctionField label='Projected Roi Percentage' render={() => `${orderProjectedRoiPercent.times(100).round()}%`} />

      <ArrayField<any> source='discrepancies' label='Discrepancies'>
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            label='Type'
            render={(discrepancy: Required<AsungOrder>['discrepancies'][number]) => {
              return sentenceCase(discrepancy.type);
            }}
          />

          <TextField<any> source='description' label='Description' />
        </Datagrid>
      </ArrayField>

      <ArrayField<any> source='items' label='Order Items'>
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            label='Option Codes'
            render={(item: AsungOrder['items'][number]) => {
              return (
                <>
                  {item.asungOptions.map((option, index) => (
                    <>
                      <Link href={`https://b2b.asung.ca/products/details/${option.productRef}`} target='_blank'>
                        {option.optionCode}
                      </Link>

                      {index !== item.asungOptions.length - 1 ? ', ' : ''}
                    </>
                  ))}
                </>
              );
            }}
          />

          <FunctionField
            label='Seller Sku'
            render={(item: AsungOrder['items'][number]) => {
              return (
                <Link href={`https://sellercentral.amazon.com/myinventory/inventory?searchTerm=${item.sellerSku}`} target='_blank'>
                  {item.sellerSku}
                </Link>
              );
            }}
          />

          <NumberField<any> source='quantity' label='Quantity' />

          <FunctionField
            label='Asung Options'
            render={(item: AsungOrder['items'][number]) => {
              const uomSizes = item.asungOptions.flatMap((asungOption) => {
                return asungOption.uomSizes.map((uomSize) => {
                  const formattedPrice = currencyFormatter.format(uomSize.price);
                  const uomSizeDescription = `${uomSize.quantity}x${uomSize.optionCode}@${formattedPrice}`;

                  return uomSizeDescription;
                });
              });

              return uomSizes.join(', ');
            }}
          />

          <FunctionField
            label='Total w/o Taxes'
            render={(item: AsungOrder['items'][number]) => {
              const itemTotalWithTaxes = getOrderItemCost(item);
              return currencyFormatter.format(itemTotalWithTaxes.toNumber());
            }}
          />

          <FunctionField
            label='Historical ROI'
            render={(item: AsungOrder['items'][number]) => {
              if (!record) return null;

              const roiAmount = getOrderItemHistoricalRoiAmount(record, item);
              if (!roiAmount) return '-'; // no historical stats available

              const itemTotalWithTaxes = getOrderItemTotalCostWithTaxes(record, item);
              const roiPercentage = roiAmount.div(itemTotalWithTaxes);

              const formattedRoiAmount = currencyFormatter.format(roiAmount.toNumber());
              const formattedRoiPercentage = `${roiPercentage.times(100).round()}%`;

              return `${formattedRoiAmount} / ${formattedRoiPercentage}`;
            }}
          />

          <FunctionField
            label='Projected ROI'
            render={(item: AsungOrder['items'][number]) => {
              if (!record) return null;

              const itemTotalWithTaxes = getOrderItemTotalCostWithTaxes(record, item);
              const roiAmount = getOrderItemProjectedRoiAmount(record, item);
              const roiPercentage = roiAmount.div(itemTotalWithTaxes);

              const formattedRoiAmount = currencyFormatter.format(roiAmount.toNumber());
              const formattedRoiPercentage = `${roiPercentage.times(100).round()}%`;

              return `${formattedRoiAmount} / ${formattedRoiPercentage}`;
            }}
          />
        </Datagrid>
      </ArrayField>

      <ArrayField<any> source='boxes' label='Box Details'>
        <Datagrid bulkActionButtons={false}>
          <NumberField<any> source='boxNumber' label='Box #' />
          <NumberField<any> source='weightInKgs' label='Weight (Kgs)' />

          <FunctionField
            label='Dimensions'
            render={(box: Required<AsungOrder>['boxes'][number]) => {
              const { length, width, height } = box.dimensionsInCm;

              const description = `${length}x${width}x${height} cm`;
              return <Typography>{description}</Typography>;
            }}
          />

          <FunctionField
            label='Products'
            render={(box: Required<AsungOrder>['boxes'][number]) => {
              return (
                <Grid container>
                  {box.products.map((product) => {
                    const description = `${product.fnSku} x ${product.quantity}`;
                    return <Typography key={product.fnSku}>{description}</Typography>;
                  })}
                </Grid>
              );
            }}
          />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

export const ShowAsungOrder: React.FC = (props) => {
  return (
    <Show {...props} actions={<ShowAsungOrderActions />}>
      <ShowAsungOrderLayout />
    </Show>
  );
};
