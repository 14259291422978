import React from 'react';
import { Edit, TextInput, SimpleForm, ArrayInput, SimpleFormIterator, NumberInput } from 'react-admin';

export const EditAsungOrder: React.FC = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ArrayInput source='items'>
          <SimpleFormIterator inline>
            <TextInput source='sellerSku' />
            <NumberInput source='quantity' />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
