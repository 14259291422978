import React from 'react';
import { Link, Grid, Typography } from '@mui/material';
import { Show, TextField, NumberField, DateField, SimpleShowLayout, BooleanField, FunctionField, ArrayField, Datagrid } from 'react-admin';
import { FbaInventoryItem } from '../types';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'CAD',
  minimumFractionDigits: 2,
});

const ShowFbaInventoryItemLayout: React.FC = () => {
  return (
    <SimpleShowLayout>
      <TextField<any> source='sellerSku' label='Seller Sku' />

      <FunctionField
        label='ASIN'
        render={(item: FbaInventoryItem) => {
          if (!item.asin) return null;

          return (
            <Link href={`https://amazon.ca/dp/${item.asin}`} target='_blank'>
              {item.asin}
            </Link>
          );
        }}
      />

      <TextField<any> source='itemName' label='Item Name' />
      <TextField<any> source='status' label='Status' />
      <BooleanField<any> source='chendaVerified' label='Chenda Verified' />
      <TextField<any> source='monthlySales' label='Monthly sales' />

      <DateField<any> source='lastSyncedAt' label='Last Synced At' showTime />
      <TextField<any> source='notes' label='Notes' />

      <ArrayField<any> source='asungOptions' label='Asung Options'>
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            label='Option Code'
            render={(option: FbaInventoryItem['asungOptions'][number]) => {
              return (
                <Link href={`https://b2b.asung.ca/products/details/${option.productRef}`} target='_blank'>
                  {option.optionCode}
                </Link>
              );
            }}
          />

          <NumberField<any> source='quantity' label='Quantity' />

          <FunctionField
            label='Uom Sizes'
            render={(option: FbaInventoryItem['asungOptions'][number]) => {
              return (
                <Grid container>
                  {option.uomSizes.map((uomSize) => {
                    const formattedPrice = currencyFormatter.format(uomSize.price);
                    const description = `${uomSize.size}@${formattedPrice}`;

                    return (
                      <Grid item xs={12}>
                        <Typography>{description}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            }}
          />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

export const ShowFbaInventoryItem: React.FC = (props) => {
  return (
    <Show {...props}>
      <ShowFbaInventoryItemLayout />
    </Show>
  );
};
