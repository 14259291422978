import { isValidElement, useEffect, useMemo } from 'react';
import { UseInfiniteQueryOptions, InfiniteQueryObserverBaseResult } from 'react-query';

import { useAuthenticated } from 'react-admin';
import { useTranslate } from 'react-admin';
import { useNotify } from 'react-admin';
import { defaultExporter } from 'react-admin';
import { RaRecord, SortPayload, FilterPayload, Exporter, GetInfiniteListResult } from 'react-admin';
import { useResourceContext, useGetResourceLabel } from 'ra-core';
import { useRecordSelection } from 'react-admin';
import { useListParams } from 'react-admin';

import { ListControllerResult } from 'react-admin';
import { useCursorGetList } from './useCursorGetList';

export const useCursorListController = <RecordType extends RaRecord = any>(
  props: CursorListControllerProps<RecordType> = {}
): CursorListControllerResult<RecordType> => {
  const {
    debounce = 500,
    disableAuthentication,
    disableSyncWithLocation,
    exporter = defaultExporter,
    filter,
    filterDefaultValues,
    perPage = 10,
    queryOptions = {},
    sort,
    storeKey,
  } = props;
  useAuthenticated({ enabled: !disableAuthentication });
  const resource = useResourceContext(props);
  const { meta, ...otherQueryOptions } = queryOptions;

  if (!resource) {
    throw new Error(`<InfiniteList> was called outside of a ResourceContext and without a resource prop. You must set the resource prop.`);
  }
  if (filter && isValidElement(filter)) {
    throw new Error(
      '<InfiniteList> received a React element as `filter` props. If you intended to set the list filter elements, use the `filters` (with an s) prop instead. The `filter` prop is internal and should not be set by the developer.'
    );
  }

  const translate = useTranslate();
  const notify = useNotify();

  const [query, queryModifiers] = useListParams({
    debounce,
    disableSyncWithLocation,
    filterDefaultValues,
    perPage,
    resource,
    sort,
    storeKey,
  });

  const [selectedIds, selectionModifiers] = useRecordSelection(resource);

  const {
    data,
    total,
    error,
    isLoading,
    isFetching,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    isFetchingNextPage,
    fetchPreviousPage,
    isFetchingPreviousPage,
    refetch,
  } = useCursorGetList<RecordType>(
    resource,
    {
      pagination: {
        page: query.page,
        perPage: query.perPage,
      },
      sort: { field: query.sort, order: query.order },
      filter: { ...query.filter, ...filter },
      meta,
    },
    {
      keepPreviousData: true,
      retry: false,
      onError: (error) =>
        notify(error?.message || 'ra.notification.http_error', {
          type: 'error',
          messageArgs: {
            _: error?.message,
          },
        }),
      ...otherQueryOptions,
    }
  );

  // change page if there is no data
  useEffect(() => {
    if (query.page <= 0 || (!isFetching && query.page > 1 && (data == null || data?.pages.length === 0))) {
      // Query for a page that doesn't exist, set page to 1
      queryModifiers.setPage(1);
      return;
    }
    if (total == null) {
      return;
    }
    const totalPages = Math.ceil(total / query.perPage) || 1;
    if (!isFetching && query.page > totalPages) {
      // Query for a page out of bounds, set page to the last existing page
      // It occurs when deleting the last element of the last page
      queryModifiers.setPage(totalPages);
    }
  }, [isFetching, query.page, query.perPage, data, queryModifiers, total]);

  const currentSort = useMemo(
    () => ({
      field: query.sort,
      order: query.order,
    }),
    [query.sort, query.order]
  );

  const getResourceLabel = useGetResourceLabel();
  const defaultTitle = translate('ra.page.list', {
    name: getResourceLabel(resource, 2),
  });

  const unwrappedData = useMemo(() => data?.pages?.reduce<RecordType[]>((acc, page) => [...acc, ...page.data], []), [data]);

  return {
    sort: currentSort,
    data: unwrappedData,
    defaultTitle,
    displayedFilters: query.displayedFilters,
    error,
    exporter,
    filter,
    filterValues: query.filterValues,
    hideFilter: queryModifiers.hideFilter,
    isFetching,
    isLoading,
    onSelect: selectionModifiers.select,
    onToggleItem: selectionModifiers.toggle,
    onUnselectItems: selectionModifiers.clearSelection,
    page: query.page,
    perPage: query.perPage,
    refetch,
    resource,
    selectedIds,
    setFilters: queryModifiers.setFilters,
    setPage: queryModifiers.setPage,
    setPerPage: queryModifiers.setPerPage,
    setSort: queryModifiers.setSort,
    showFilter: queryModifiers.showFilter,
    total: total,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    isFetchingNextPage,
    fetchPreviousPage,
    isFetchingPreviousPage,
  };
};

export interface CursorListControllerProps<RecordType extends RaRecord = any> {
  debounce?: number;
  disableAuthentication?: boolean;
  /**
   * Whether to disable the synchronization of the list parameters with the current location (URL search parameters)
   */
  disableSyncWithLocation?: boolean;
  exporter?: Exporter | false;
  filter?: FilterPayload;
  filterDefaultValues?: object;
  perPage?: number;
  // FIXME: Make it generic, but Parameters<typeof useInfiniteQuery<RecordType>>[2] doesn't work
  queryOptions?: UseInfiniteQueryOptions<GetInfiniteListResult<RecordType>, Error>;
  resource?: string;
  sort?: SortPayload;
  storeKey?: string | false;
}

export type CursorListControllerResult<RecordType extends RaRecord = any> = Omit<
  ListControllerResult<RecordType>,
  'data' | 'total' | 'hasNextPage' | 'hasPreviousPage'
> & {
  data?: RecordType[];
  total?: number;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  fetchNextPage: InfiniteQueryObserverBaseResult<GetInfiniteListResult<RecordType>>['fetchNextPage'];
  fetchPreviousPage: InfiniteQueryObserverBaseResult<GetInfiniteListResult<RecordType>>['fetchPreviousPage'];
  isFetchingNextPage: InfiniteQueryObserverBaseResult<GetInfiniteListResult<RecordType>>['isFetchingNextPage'];
  isFetchingPreviousPage: InfiniteQueryObserverBaseResult<GetInfiniteListResult<RecordType>>['isFetchingPreviousPage'];
};
