import React from 'react';
import { Datagrid, TextField } from 'react-admin';

export const FbaInventoryItemsDataGrid: React.FC = () => {
  return (
    <Datagrid bulkActionButtons={false} rowClick='show'>
      <TextField<any> source='sellerSku' label='Seller Sku' />
      <TextField<any> source='itemName' label='Name' />
      <TextField<any> source='status' label='Status' />
      <TextField<any> source='monthlySales' label='Monthly sales' />
    </Datagrid>
  );
};
