import { Resource } from 'react-admin';
import InventoryIcon from '@mui/icons-material/Inventory';
import { ShowAsungOrder, AsungOrdersList, EditAsungOrder } from './pages';
import { CreateAsungOrder } from './pages/asung-orders.create-page';

export const AsungOrdersResource = (
  <Resource
    name='asung-orders'
    icon={InventoryIcon}
    list={AsungOrdersList}
    show={ShowAsungOrder}
    edit={EditAsungOrder}
    create={CreateAsungOrder}
    options={{ label: 'Asung Orders' }}
  />
);
