import { Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { useGetList } from 'react-admin';
import { usePusherClient } from '../../../hooks/pusher';
import { useEffect } from 'react';

type UserOperationsStats = {
  id: string;
  date: string;
  verifiedProductsCount: number;
  verifiedProductsTarget: number;
};

export const VerifiedProducts: React.FC = () => {
  const pusherClient = usePusherClient();
  const { data, isLoading, refetch } = useGetList<UserOperationsStats>('user-operations-stats');
  const [stats] = data ?? [];

  const progressText = `${stats?.verifiedProductsCount} / ${stats?.verifiedProductsTarget}`;

  useEffect(() => {
    const channel = pusherClient.subscribe(`user-operatiosn-stats.${stats?.id}`);

    channel.bind('updated', (updatedStatus) => {
      if (updatedStatus?.id === stats?.id) {
        console.log('reloading stats ::', updatedStatus);
        refetch();
      }
    });

    return () => channel.unsubscribe();
  }, [refetch, stats?.id]);

  return (
    <Card>
      <CardContent>
        <Typography variant='h2'>Products Verified Today</Typography>
        {isLoading && <CircularProgress size={32} />}
        {!isLoading && <Typography variant='h4'>{progressText}</Typography>}
      </CardContent>
    </Card>
  );
};
